import React from 'react'
import { Container, Text } from '@nextui-org/react'

export default function FourOFour() {
    return (
        <div>
            <Container xs>
                <Text>The page you're looking for does not exist</Text>
            </Container>
        </div>
    )
}